// import React from 'react'
// import { useLocation } from "@reach/router"

import axios from 'axios';

const STORAGE_NAME = 'otin'; 

const InterestTracker = (interest,location) => {

    const { hostname,pathname,search } = location;

    let apihost = ''; 

    var trackingObject = {"guid":""}; 
    var existingTracker = (typeof window === "undefined") ? null:window.localStorage.getItem(STORAGE_NAME); 
    if(existingTracker!==null){ 
        trackingObject = JSON.parse(existingTracker); 
    }

    if(interest===undefined){ interest = 'Unspecified'; }

    // var now = new Date(); 

    var record = {}; 
    record.source = pathname; 
    record.interestName = interest; 
    // record.timestamp = now.toISOString(); 
    if(trackingObject.guid!==''){ 
        record.uuid = trackingObject.guid; 
    }
    
    if(search!==""){ 
        var querystring = search.substring(1); 
        var querystringarray = querystring.split('&'); 
        var thisParam; 
        record.promo = {}; 
        for(var i=0;i<querystringarray.length;i++){ 
            thisParam = querystringarray[i].split('='); 
            switch(thisParam[0]){ 
                case "utm_id": 
                    record.promo.id = thisParam[1]; 
                    break; 
                case "utm_campaign": 
                    record.promo.campaign = thisParam[1]; 
                    break; 
                case "utm_source": 
                    record.promo.source = thisParam[1]; 
                    break; 
                case "utm_medium": 
                    record.promo.medium = thisParam[1]; 
                    break; 
                case "utm_content": 
                    record.promo.content = thisParam[1]; 
                    break; 
                case "utm_term": 
                    record.promo.term = thisParam[1]; 
                    break; 
                default: 
                    break; 
            }
        }
    }

    if(hostname === 'www.outward.travel' || hostname==='outward.travel'){ 
        apihost = process.env.GATSBY_API_URL; 
    } else { 
        apihost = 'https://port-3000-traveletica-rookbehr.codeanyapp.com/api/public'; 
    }
    axios.post(apihost+"/website/outward/outward/stats",record)
        .then(result => {
            if(result.data.status==='ok'){ 
                if(result.data.uuid!==undefined && result.data.uuid!==null && result.data.uuid!==''){ 
                    trackingObject.guid = result.data.uuid; 
                    if(typeof window !== "undefined") window.localStorage.setItem(STORAGE_NAME, JSON.stringify(trackingObject));
                } 
            }  
        });


    // var existingTracker = (typeof window === "undefined") ? null:window.localStorage.getItem(STORAGE_NAME); 
    // var trackingObject; 
    // if(existingTracker===null){ 
    //     trackingObject = {}; 
    //     trackingObject.guid = ''; 
    //     trackingObject.records = []; 
    // } else { 
    //     trackingObject = JSON.parse(existingTracker); 
    // }

    // trackingObject.records.push(record); 

    

}

const GetGUID = () => {
    if(typeof window !== "undefined"){ 
        var existingTracker = window.localStorage.getItem(STORAGE_NAME); 
        if(existingTracker===null){ 
            return null; 
        } else { 
            let trackerId = JSON.parse(existingTracker); 
            if(trackerId.guid!==undefined && trackerId.guid!==null){ 
                return trackerId.guid; 
            } else { 
                return null;
            }
        }
    } else { 
        return null;
    }
} 

// const GetInterests = () => {
//     if(typeof window !== "undefined"){ 
//         var existingTracker = window.localStorage.getItem(STORAGE_NAME); 
//         if(existingTracker===null){ 
//             return null; 
//         } else { 
//             return JSON.parse(existingTracker); 
//         }
//     } else { 
//         return {}
//     }
// } 

// const ClearInterests = (fullClear) => {
//     if(fullClear===undefined || fullClear===false){ 
//         let interests = GetInterests(); 
//         if(interests!==null){ 
//             interests.records = []; 
//             if(typeof window !== "undefined") window.localStorage.setItem(STORAGE_NAME, JSON.stringify(interests));
//         }
//     } else if(fullClear===true){ 
//         if(typeof window !== "undefined") window.localStorage.removeItem(STORAGE_NAME); 
//     }
// } 


export {InterestTracker,GetGUID}; 
