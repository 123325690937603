function GoogleAnalyticsEvent(event, detail) {
    if(typeof window === "undefined") return false; 
    if(typeof window.gtag==="undefined"){ 
        console.log("Mock GAx Event: "+event+' '+JSON.stringify(detail)); 
    } else { 
        window.gtag("event",event,detail);
    }
}

export default GoogleAnalyticsEvent;
