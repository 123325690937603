/* Adding Navigation? 
   Remember to update SectionMenu.js too! */ 
   
import React, { useState } from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Dropdown from 'react-bootstrap/Dropdown'
import NavItem from 'react-bootstrap/NavItem'
import { Link } from 'gatsby'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faPhoneRotary } from '@fortawesome/pro-solid-svg-icons'

export default function GlobalNav({ children }) {
    const [showVacationIdeasMenu, setShowVacationIdeasMenu] = useState(false);
    // const [showGroupTravelMenu, setShowGroupTravelMenu] = useState(false);
    const [showAboutUsMenu, setShowAboutUsMenu] = useState(false);


  return (
    
<Navbar collapseOnSelect expand="lg" className="GlobalNav">
  <Navbar.Brand href="/">
    <svg id="Logo" aria-labelledby="Logo_Title" className="standard-logo" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="280" height="49" viewBox="0 0 280 49">
        <title id="Logo_Title">Outward Travel Logo</title>
        <g>
            <path class="travel travel-t" d="M183.0918,16.3091V44.4619h-2.0859V16.3091h-8.25V14.314h18.5869v1.9951Z"/>
            <path class="travel travel-r" d="M200.8623,24.061a7.1137,7.1137,0,0,0-2.2217-.4082,6.2214,6.2214,0,0,0-4.4424,1.7686,6.5036,6.5036,0,0,0-1.36,1.9946,5.6814,5.6814,0,0,0-.4981,2.3125V44.4619H190.209V29.7285a7.5987,7.5987,0,0,1,1.292-4.33,8.7142,8.7142,0,0,1,3.5586-3.0156,8.2932,8.2932,0,0,1,3.581-.77,8.4073,8.4073,0,0,1,4.4883,1.2246Z"/>
            <path class="travel travel-a" d="M222.3506,44.4619V39.248a11.4168,11.4168,0,0,1-4.0567,4.126,10.6647,10.6647,0,0,1-5.3271,1.3145,11.349,11.349,0,0,1-4.42-.8614,11.1984,11.1984,0,0,1-3.6494-2.4482,10.8763,10.8763,0,0,1-2.4707-3.6494,11.3769,11.3769,0,0,1,10.54-15.8442,11.8416,11.8416,0,0,1,5.6445,1.27,9.0659,9.0659,0,0,1,3.7393,3.7627V22.4292h2.0859V44.4619Zm-13.0332-19.811a9.0564,9.0564,0,0,0-5.01,5.0093,9.42,9.42,0,0,0-.7246,3.6494,9.21,9.21,0,0,0,5.7344,8.6133,9.4017,9.4017,0,0,0,3.6494.7255,9.1249,9.1249,0,0,0,3.5811-.7255,9.2486,9.2486,0,0,0,2.9921-1.9942,9.5893,9.5893,0,0,0,2.0176-2.9922,9.0041,9.0041,0,0,0,.7481-3.6269A9.4211,9.4211,0,0,0,221.58,29.66a9.2961,9.2961,0,0,0-8.6133-5.7349A9.4182,9.4182,0,0,0,209.3174,24.6509Z"/>
            <path class="travel travel-v" d="M238.127,44.4619h-2.6749l-8.9755-22.5766h2.2207l8.1152,20.2641,8.0693-20.2641h2.2217Z"/>
            <path class="travel travel-e" d="M249.0068,34.3066a10.3533,10.3533,0,0,0,3.31,6.03,8.6931,8.6931,0,0,0,5.9844,2.2666,8.9863,8.9863,0,0,0,4.3066-1.043,9.9769,9.9769,0,0,0,3.3086-2.8564l1.8135.998a11.9465,11.9465,0,0,1-4.08,3.6495,10.9741,10.9741,0,0,1-5.3486,1.3369,11.1553,11.1553,0,0,1-4.3975-.8838,11.528,11.528,0,0,1-6.1435-6.1426,11.1582,11.1582,0,0,1-.8838-4.3975,11.4953,11.4953,0,0,1,3.3545-8.1157,10.6463,10.6463,0,0,1,3.6494-2.4482,11.7739,11.7739,0,0,1,8.8408,0,10.6558,10.6558,0,0,1,3.6494,2.4482,12.124,12.124,0,0,1,2.4248,3.854,11.96,11.96,0,0,1,.9746,4.5791l-.0449.7246Zm3.31-8.1606a10.3592,10.3592,0,0,0-3.31,6.0308h18.587a10.3592,10.3592,0,0,0-3.31-6.0308,9.0321,9.0321,0,0,0-11.9678,0Z"/>
            <path class="travel travel-l" d="M272.7158,44.4619V14.314h2.086V44.4619Z"/>
        </g>
        <path class="outward outward-o" data-name="Outward-O" d="M28.9246,24.88a11.9656,11.9656,0,0,1-4.9993,15.0621,12.0354,12.0354,0,0,1-11.9957,0,11.8372,11.8372,0,0,1-4.3517-4.3517,12.0354,12.0354,0,0,1,0-11.9957A11.8494,11.8494,0,0,1,11.93,19.243a12.0644,12.0644,0,0,1,11.0234-.5078L25.4586,16.56a14.6677,14.6677,0,0,0-7.5311-2.0293A14.547,14.547,0,0,0,10.4189,16.56,15.1947,15.1947,0,0,0,4.94,22.039a15.031,15.031,0,0,0,0,15.0847A15.3044,15.3044,0,0,0,10.3964,42.58,14.5084,14.5084,0,0,0,17.9275,44.61,14.6306,14.6306,0,0,0,25.4811,42.58,15.1947,15.1947,0,0,0,30.96,37.1012a14.9555,14.9555,0,0,0,.3659-14.3771"/>
        <path class="outward outward-u" data-name="Outward-u" d="M39.9568,43.37a9.0171,9.0171,0,0,1-3.27-3.247,8.6321,8.6321,0,0,1-1.2176-4.487v-13.8h3.1116v13.8a5.7126,5.7126,0,0,0,.7893,2.9312,5.8483,5.8483,0,0,0,2.1421,2.1422,5.8387,5.8387,0,0,0,5.8624,0,5.8546,5.8546,0,0,0,2.1422-2.1422,5.72,5.72,0,0,0,.7892-2.9312v-13.8H53.373V44.1587H50.3065V41.9942a7.49,7.49,0,0,1-2.6382,1.9165,8.0274,8.0274,0,0,1-3.2244.654A8.7549,8.7549,0,0,1,39.9568,43.37Z"/>
        <path class="outward outward-t" data-name="Outward-t" d="M66.9019,24.9026h-3.743V40.5777c-.1407,2.9692,3.71,3.8066,3.71,3.8066s-6.7511,2.0358-6.8214-2.9679V24.9026H56.3042V21.836h3.7431V14.3951h3.1116V21.836h3.743Z"/>
        <path class="outward outward-w" data-name="Outward-w" d="M103.0235,21.836,94.14,44.3843h-3.247l-4.645-11.68-4.645,11.68H78.3558L69.3816,21.836h3.3372L79.9793,40.1l4.6-11.5447L81.9184,21.836h3.3372L92.5162,40.1,99.7315,21.836Z"/>
        <path class="outward outward-a" data-name="Outward-a" d="M125.5714,22.4223v21.962H122.46V40.2354a9.6989,9.6989,0,0,1-8.2977,4.3744,11.244,11.244,0,0,1-5.7274-1.5107,11.2755,11.2755,0,0,1-5.6369-9.8086,11.2451,11.2451,0,0,1,1.5107-5.7272,11.0944,11.0944,0,0,1,4.1262-4.1263,11.25,11.25,0,0,1,5.7274-1.5108,9.4965,9.4965,0,0,1,5.2536,1.33A10.0594,10.0594,0,0,1,122.46,26.12V22.4223Zm-7.26,18.016a8.1964,8.1964,0,0,0,2.9988-2.999,8.0859,8.0859,0,0,0,1.105-4.1488,8.2942,8.2942,0,0,0-4.1038-7.1928,8.09,8.09,0,0,0-4.1488-1.1049,8.2091,8.2091,0,0,0-4.1715,1.1049,8.0953,8.0953,0,0,0-3.0215,3.0214,8.2047,8.2047,0,0,0-1.1048,4.1714,8.1786,8.1786,0,0,0,4.1263,7.1478,8.2011,8.2011,0,0,0,4.1715,1.1049A8.0824,8.0824,0,0,0,118.3109,40.4383Z"/>
        <path class="outward outward-r" data-name="Outward-r" d="M137.2056,24.7222a5.2356,5.2356,0,0,0-5.186,5.1862V44.3843H128.953V29.9084a8.2509,8.2509,0,0,1,4.6449-7.441l.09-.0451a8.3293,8.3293,0,0,1,3.5175-.7666,8.1353,8.1353,0,0,1,5.141,1.7588L139.28,25.1732A5.1754,5.1754,0,0,0,137.2056,24.7222Z"/>
        <path class="outward outward-d" data-name="Outward-d" d="M163.632,44.3843H160.61V40.19a10.0882,10.0882,0,0,1-8.433,4.4195,11.1174,11.1174,0,0,1-5.7272-1.5334,11.375,11.375,0,0,1-4.1489-4.1713,11.38,11.38,0,0,1,0-11.4095,11.38,11.38,0,0,1,4.1489-4.1713,11.1241,11.1241,0,0,1,5.7272-1.5333,9.9511,9.9511,0,0,1,8.433,4.1939V14.44h3.0216Zm-4.2392-15.3328a8.5031,8.5031,0,0,0-3.0439-3.0215,8.0885,8.0885,0,0,0-4.1715-1.1274,7.9665,7.9665,0,0,0-4.1489,1.1274,8.554,8.554,0,0,0-3.0214,3.0215A7.9616,7.9616,0,0,0,143.88,33.2a8.0775,8.0775,0,0,0,1.1274,4.1714,8.486,8.486,0,0,0,3.0214,3.0441,7.96,7.96,0,0,0,4.1489,1.1274,8.0818,8.0818,0,0,0,4.1715-1.1274,8.4359,8.4359,0,0,0,3.0439-3.0441A8.0834,8.0834,0,0,0,160.52,33.2,7.9673,7.9673,0,0,0,159.3928,29.0515Z"/>

        <path class="plane" d="M34.841,18.4417c1.1379-1.0231.4072-2.024.283-3.7874a35.7921,35.7921,0,0,0,3.0695-2.404q1.5152,3.6612,3.0309,7.323c.5849-.8994.7011-1.3787.5692-3.25q-.2422-3.4372-.4847-6.8744,2.1168-1.9035,4.2344-3.8069c.503-.4526,1.4026-2.1049.9868-2.5673s-2.1539.2569-2.6571.7092L40.6789,6.6561l-.0481.043-.066.06-.0661.0595-.0662.0595-.0661.0595-.0663.06-.0661.0592-.0662.06-.0661.0595-.0663.0595-.066.0595-.0663.06-.066.06-.0664.0592-.0661.0595-.066.06L36.52,10.3957A35.7075,35.7075,0,0,0,33.8074,13.19c-1.7405-.31-2.6581-1.143-3.796-.12a21.1258,21.1258,0,0,1,4.83,5.3722ZM38.0647,7.31,35.3483,9.7526l-5.7857-3.15c.9564-.4864,1.4452-.5511,3.2918-.2216Z"/>
        <path class="swoosh-right" d="M32.6579,17.2827,9.4287,38.0147a12.1371,12.1371,0,0,0,3.8268,2.5933,59.3518,59.3518,0,0,1,7.0553-9.0307c4.1355-4.4942,11.7368-11.6085,13.2513-13.0194A15.6247,15.6247,0,0,0,32.6579,17.2827Z"/>
        <path class="swoosh-left" d="M29.9666,14.5294c-1.5733,1.345-9.5025,8.0917-14.4362,11.6917A61.5225,61.5225,0,0,1,6.21,31.995a11.6135,11.6135,0,0,0,1.3681,3.5958q.1923.3308.4019.6441L31.1313,15.5722A15.5857,15.5857,0,0,0,29.9666,14.5294Z"/>
        <path class="swoosh-middle" d="M8.9321,37.4829,32.1953,16.7205c-.0843-.098-.169-.1956-.2555-.2918-.1075-.12-.2175-.2369-.3283-.3533L8.3855,36.8046C8.5607,37.0376,8.7417,37.265,8.9321,37.4829Z"/>
    </svg>
  </Navbar.Brand>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />




  <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
    <Nav>
        
      <NavDropdown title="Vacation Ideas" id="globalnav-vacationideas" 
        onMouseEnter={()=>{setShowVacationIdeasMenu(true)}}
        onMouseLeave={()=>{setShowVacationIdeasMenu(false)}}
        show={showVacationIdeasMenu}
        onClick={()=>{ window.location="/vacations"}}
        >
        <NavDropdown.Item as={Link} to="/vacations/adventure" onClick={(e)=>{e.stopPropagation()}}>Adventure</NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/vacations/cruises" onClick={(e)=>{e.stopPropagation()}}>Cruises</NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/vacations/lgbtq" onClick={(e)=>{e.stopPropagation()}}>LGBTQ+ &amp; Poly-Friendly</NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/vacations/spiritual" onClick={(e)=>{e.stopPropagation()}}>Spiritual Restoration</NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/vacations/disney" onClick={(e)=>{e.stopPropagation()}}>Disney World</NavDropdown.Item>
      
  {/*
        <NavDropdown.Divider />
        <NavDropdown.Item as={Link} to="/vacations/favorites" onClick={(e)=>{e.stopPropagation()}}>Our Favorite Places</NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/vacations" onClick={(e)=>{e.stopPropagation()}}>More Ideas</NavDropdown.Item>
      */}
      </NavDropdown>  
      {/*
      <NavDropdown title="Group Travel" id="globalnav-groups"
        onMouseEnter={()=>{setShowGroupTravelMenu(true)}}
        onMouseLeave={()=>{setShowGroupTravelMenu(false)}}
        show={showGroupTravelMenu}
        onClick={()=>{ window.location="/groups"}}
        >
        <NavDropdown.Item as={Link} to="/groups/family-reunions" onClick={(e)=>{e.stopPropagation()}}>Family Reunions</NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/groups/friends" onClick={(e)=>{e.stopPropagation()}}>Guys &amp; Gals Getaways</NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/groups/corporate" onClick={(e)=>{e.stopPropagation()}}>Corporate Trips</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item as={Link} to="/groups/tips" onClick={(e)=>{e.stopPropagation()}}>Tips for Planning Group Travel</NavDropdown.Item>
      </NavDropdown>
      */}
      <Nav.Item>
          <Link to="/groups">Group Travel</Link>
      </Nav.Item>
      <Nav.Item>
          <Link to="/events">Branded Events</Link>
      </Nav.Item>
      <NavDropdown title="About Us" id="globalnav-about"
        onMouseEnter={()=>{setShowAboutUsMenu(true)}}
        onMouseLeave={()=>{setShowAboutUsMenu(false)}}
        show={showAboutUsMenu}
        onClick={()=>{ window.location="/about"}}
        align="end"
        >
        <NavDropdown.Item as={Link} to="/about" onClick={(e)=>{e.stopPropagation()}}>Meet Outward</NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/about/codeofconduct" onClick={(e)=>{e.stopPropagation()}}>Code of Conduct</NavDropdown.Item>

      {/*
        <NavDropdown.Item as={Link} to="/about/why-travel-agency" onClick={(e)=>{e.stopPropagation()}}>Why Use a Travel Agency?</NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/about/process" onClick={(e)=>{e.stopPropagation()}}>Our Process</NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/about/contact" onClick={(e)=>{e.stopPropagation()}}>Contact Us</NavDropdown.Item>
      */}
      </NavDropdown>
    </Nav>
  </Navbar.Collapse>
</Navbar>
  )
}








