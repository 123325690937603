
import React, { useState } from 'react';
import styled from "styled-components"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import GoogleAnalyticsEvent from "../background/GoogleAnalyticsEvent.js";

import axios from 'axios';
import {GetGUID} from "../background/InterestTracker.js"

import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
// import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'

import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

const FormBody = styled.div`
    display:block; 
    &.inline{ 
        margin:25px 0px;
        padding:15px;
        border-top-left-radius:20px; 
        border-bottom-right-radius:20px; 
    }
    &.vertical{ 
        margin-top:0px; 
    }
    &.blue{ 
        background-color:rgba(8,128,215,.25);
    }
    &.vertical .field{
        padding-top:5px;
        padding-bottom:5px;
    }
    &.vertical .button{
        text-align:center; 
    }
    & .Intro h6{ 
        font-weight:bold; 
        font-size:0.9em; 
        margin-bottom:3px; 
        line-height:1.1em; 
    }
    & .Intro p{ 
        margin-top:0px; 
        margin-bottom:0px;  
        font-size:0.9em; 
        line-height:1.2em; 
    }
    &.vertical .Intro h6,
    &.vertical .Intro p{
        line-height:1.35em; 
    }
    @media (max-width:1199.98px) {  
        & .Intro p{ 
            margin-bottom:10px; 
        }   
    }
    
`; 
const FormDone = styled(Col)`
    text-align:center; 
`; 
const FormButton = styled(Button)`
	display:inline; 
	background-color:#008BE0; 
    border:0px; 
    height:40px; 
    margin-top:10px; 
	border-radius: 20px;
    color: white;
    outline: none;
    font-size:1.1em; 
    padding-right:20px; 
    padding-left:15px; 
    text-align:center; 
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

    &.submit{ 
        width:150px; 
    }
    @media (max-width:767.98px) {  
        width:100%; 
        &.submit{ 
            width:100%; 
        }
    }

	&:hover {
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }
	&.green{ 
		background-color:#169300; 
	} 
	&.green.inverse{ 
        border:1px solid #169300; 
        background-color:#FFFFFF; 
	} 
	&.blue{ 
		background-color:#008BE0; 
	} 
	&.blue.inverse{ 
        background-color:#FFFFFF;
        border:1px solid #008BE0; 
        color:#008BE0; 
	} 
	&.inverse{ 
        background-color:#FFFFFF;
        border:1px solid #008BE0; 
        color:#008BE0; 
	} 
	&.yellow{ 
        background-color:#fcae00; 
        color:#000000; 
	} 
	&.yellow.inverse{ 
        background-color:#FFFFFF;
        border:1px solid #fcae00; 
        color:#fcae00; 
	} 
	&.red{ 
        background-color:#a50000; 
	} 
	&.red.inverse{ 
        background-color:#FFFFFF;
        border:1px solid #a50000; 
        color:#a50000;
	} 
	&.orange{ 
        background-color:#C96E00; 
	} 
	&.orange.inverse{ 
        background-color:#FFFFFF;
        border:1px solid #C96E00; 
        color:#C96E00; 
	} 
	&.purple{ 
        background-color:#7500C9; 
	} 
	&.gray{ 
        background-color:#999999; 
	} 
	&.gray.inverse{ 
        border:1px solid #999999; 
        background-color:#FFFFFF; 
        color:#999999; 
	} 
	&.purple.inverse{ 
        background-color:#FFFFFF;
        border:1px solid #7500C9; 
        color:#7500C9; 
	} 
    &:disabled{ background-color:#999999; box-shadow:none; }
`;

const FormattedFloatingLabel = styled(FloatingLabel)`
    & > label{ 
        font-style:italic; 
        color:#444; 
    }
`; 
const Error = styled.div`
    border:1px solid #a50000;
    color:#a50000; 
    background-color:#ffc5c5; 
    border-radius:10px; 
    padding:10px; 
    display:flex; 
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start; 
    margin-top:20px; 
    &.validation{ 
        border:1px solid #fcae00; 
        background-color:#efc97b; 
        color:#000; 
    }
    &.validation > svg{ 
        color:#C96E00
    }
    & > svg{ 
        margin-right:10px; 
    }
    & > p{ 
        margin-bottom:0px; 
    }
    & > p > .detail{ 
        font-size:0.8em; 
        display:block; 
    }
`;


export default function NewsletterSubscriptionForm({disposition,color,format,children}) {

    if(disposition===undefined) disposition = 'inline'; 
    if(format===undefined) format = 'horizontal'; 
    if(color===undefined) color = 'blue'; 
    if(children===undefined) children = <><h6>Subscribe to News &amp; Promotions</h6>
                        <p>Join our newsletter and be the first to know when we have awesome new promotions to share.</p></>; 

    const [email,setEmail] = useState(''); 
    const [firstname,setFirstname] = useState(''); 

    const [formDone,setFormDone] = useState(false); 
    const [isSendingForm,setIsSendingForm] = useState(false); 
    const [submitErrorMessage,setSubmitErrorMessage] = useState(null); 
    const [submitErrorDetail,setSubmitErrorDetail] = useState(null); 
    const [validationErrorMessage,setValidationErrorMessage] = useState(null); 


    function submitForm(){ 

        if(isSendingForm) return false; 

        setSubmitErrorMessage(null); 
        setValidationErrorMessage(null); 
        setSubmitErrorDetail(null); 
        setIsSendingForm(true); 

        let re = /^[^\s@]+@[^\s@]+$/;
        if(email===""){ 
            GoogleAnalyticsEvent("validation_error", {failure:"email format"}); 
            setIsSendingForm(false); 
            setValidationErrorMessage("Please enter an email address.")
            return false; 
        }
        if(email!=="" && !re.test(email)){ 
            GoogleAnalyticsEvent("validation_error", {failure:"email format"}); 
            setIsSendingForm(false); 
            setValidationErrorMessage("Something looks funny about the email address you entered. Please double check it and send again.")
            return false; 
        }

        let guid = GetGUID(); 

        var data = {
            email:email,
            firstName:firstname
        }

        if(guid!==null) data.uuid = guid; 

        axios.post(process.env.GATSBY_API_URL+"/website/outward/outward/subscribers",data)
            .then(result => {
                if(result.data.status==='ok'){ 
                    GoogleAnalyticsEvent("newsletter_subscribe"); 
                    setIsSendingForm(false); 
                    setFormDone(true); 
                }  
            }).catch(error => {
                setIsSendingForm(false); 
                if (error.response) {
                    GoogleAnalyticsEvent("newsletter_subscribe_error", {errorType:error.response.data.error}); 
                    setSubmitErrorMessage(error.response.data.message); 
                    setSubmitErrorDetail(error.response.data.detail); 
                } else if (error.request) {
                    GoogleAnalyticsEvent("newsletter_subscribe_error", {errorType:'server-unresponsive'}); 
                    setSubmitErrorMessage("Sorry, but our mailing list provider couldn't subscribe you right now. Please try again later."); 
                    setSubmitErrorDetail("No response from server."); 
                } else {
                    GoogleAnalyticsEvent("newsletter_subscribe_error", {errorType:'axios-error'}); 
                    setSubmitErrorMessage("Sorry, but our mailing list provider couldn't subscribe you right now. Please try again later."); 
                    setSubmitErrorDetail(error.message); 
                }
            });

    }

  return (
            <FormBody className={disposition+' '+color+' '+format}>
                {!formDone &&
                <><Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={(format==='vertical')?12:4} className="Intro">
                        {children}
                    </Col>
                    <Col className="field" xs={(format==='vertical')?12:6} sm={(format==='vertical')?12:6} md={(format==='vertical')?12:4} lg={(format==='vertical')?12:4} xl={(format==='vertical')?12:3} xxl={(format==='vertical')?12:3} >
                        <FormattedFloatingLabel controlId="newslettersubscribe_name" label="First name">
                            <Form.Control type="text" placeholder="First Name" value={firstname} onChange={e => { setFirstname(e.target.value); }}/>
                        </FormattedFloatingLabel>
                    </Col>
                    <Col className="field" xs={(format==='vertical')?12:6} sm={(format==='vertical')?12:6} md={(format==='vertical')?12:4} lg={(format==='vertical')?12:5} xl={(format==='vertical')?12:3} xxl={(format==='vertical')?12:3} >
                        <FormattedFloatingLabel controlId="newslettersubscribe_email" label="Email address">
                            <Form.Control type="email" placeholder="Email address" value={email} onChange={e => { setEmail(e.target.value); }}/>
                        </FormattedFloatingLabel>
                    </Col>
                    <Col className="button" xs={12} sm={12} md={(format==='vertical')?12:3} lg={(format==='vertical')?12:3} xl={(format==='vertical')?12:2} xxl={(format==='vertical')?12:2}>
                        <FormButton className={"submit "+color} onClick={submitForm}>{isSendingForm===true ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />:"Subscribe"}</FormButton>
                    </Col>
                </Row>
                { (submitErrorMessage || validationErrorMessage) && <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={(format==='vertical')?12:{span:8,offset:4}}>
                        {submitErrorMessage!==null && <Error><FontAwesomeIcon icon={faExclamationTriangle} size="lg" /> <p>{submitErrorMessage} {submitErrorDetail && <span className="detail">{submitErrorDetail}</span>}</p></Error>}
                        {validationErrorMessage!==null && <Error className="validation"><FontAwesomeIcon icon={faExclamationCircle} size="lg" /> <p>{validationErrorMessage}</p></Error>}
                    </Col>
                </Row> }
                </> }
                {formDone && <FormDone>
                    <p>Thanks for subscribing to our newsletter!</p>
                </FormDone>}
            </FormBody>
  )
}






